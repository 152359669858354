import React from 'react';
import PageEnvelope from './PageEnvelope';
import { Link, Typography, Box } from '@material-ui/core';

function Home(props) {
    return (
        <PageEnvelope>
            <Box mt={1} mb={1}>
                <Typography component="h1" variant="h5">Developer Documentation</Typography>
            </Box>
            <Box>
                <Link href="overview">
                    API Overview and Flow
                </Link>
            </Box>
            <Box mt={1}>
                <Link href="auth-info">
                    Authorization and User Linkage
                </Link>
            </Box>
            <Box mt={1}>
                <Link href="api-spec">
                    Detailed API Spec
                </Link>
            </Box>
        </PageEnvelope>
    );
}

export default Home;