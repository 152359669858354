import React, { lazy, Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';

import Home from './Home';

const ApiSpec = lazy(() => import('./Partner'));
const Overview = lazy(() => import('./FlowOverview'));
const AuthInfo = lazy(() => import('./AuthInfo'));

const oktaConfig = {
    issuer: 'https://lawpro.oktapreview.com/oauth2/default',
    clientId: '0oagblzfnbLAXfbfQ1d6',
    redirectUri: window.location.origin + '/implicit/callback',
    tokenManager: {
        expireEarlySeconds: 180
    }
};

const oktaAuth = new OktaAuth(oktaConfig);

function Routes() {
    return (
        <Security oktaAuth={oktaAuth}>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <SecureRoute path="/" exact component={Home} />
                    <SecureRoute path="/api-spec" exact component={ApiSpec} />
                    <SecureRoute path="/overview" exact component={Overview} />
                    <SecureRoute path="/auth-info" exact component={AuthInfo} />
                    <Route path="/implicit/callback" component={LoginCallback} />
                </Switch>
            </Suspense>
        </Security>
    );
}

function RouterSetup(props) {
    return (
        <Router>
            <Routes />
        </Router>
    );
}

export default RouterSetup;