import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import lawproLogo from 'img/tp_logo.svg';

const useStyles = makeStyles(theme => ({
  logo: {
    marginBottom: theme.spacing(1.5),
    width: '25%'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

function Copyright() {
  return <Typography variant="body2" color="textSecondary" align="center">
    Copyright © Lawyers' Professional Indemnity Company {new Date().getFullYear()}
  </Typography>
}

function PageEnvelope(props) {
  const { children } = props;

  const classes = useStyles();

  return (
    <Container component="div" maxWidth="lg">
      <div className={classes.paper}>
        <img src={lawproLogo} className={classes.logo}></img>
        {children}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default PageEnvelope;